import React, { Component } from "react"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

export default class Tutorial extends Component {
  render() {
    const language = Language.CH
    return (
      <div className="min-h-screen bg-gray-100">
        <SEO
          title="Arus Oil - 隐私政策"
          description='My Protech Sdn Bhd (1307580-A) ("My Protech") 保证我们将采取预防措施以确保您的隐私和个人数据受到保护'
        />
        <Header page="隐私政策" />
        <header>
          <div className="pt-14 max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900">
              {localeContent(language).privacypolicy.termsandcondition.title1}
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="block px-4 py-8 sm:px-0">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <div className="bg-white px-4 py-3 flex flex-col items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="relative max-w-7xl text-justify pt-6 pb-16 sm:px-6">
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph1
                        }
                        <br />
                        <br />
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph2
                        }
                        <br />
                        <br />
                        <br />
                        <span className="font-bold text-xl">
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.definition.title
                          }
                        </span>
                        <br />
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.definition.paragraph1
                        }
                        <a href="/termscondition">
                          <span className="text-arusgreen-600 font-bold hover:text-arusgreen-500">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.definition.reference1
                            }
                          </span>
                        </a>
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.definition.terms
                        }
                      </p>
                      <h2 className="mt-10 text-xl font-bold">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title2
                        }
                      </h2>
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title3
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.informationCreation.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCreation.bold1
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCreation.point1
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCreation.bold2
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCreation.point2
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCreation.bold3
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCreation.point3
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCreation.bold4
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCreation.point4
                          }
                        </li>
                      </ul>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title4
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.informationMandatorily.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationMandatorily.bold1
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationMandatorily.point1
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationMandatorily.bold2
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationMandatorily.point2
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationMandatorily.bold3
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationMandatorily.point3
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationMandatorily.bold4
                            }
                          </span>
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationMandatorily.bold5
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationMandatorily.point5
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationMandatorily.bold6
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationMandatorily.point6
                          }
                        </li>
                      </ul>
                      <h2 className="mt-10 text-xl font-bold">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title5
                        }
                      </h2>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.informationCollected.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCollected.bold1
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCollected.point1
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCollected.bold2
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCollected.point2
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCollected.bold3
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCollected.point3
                          }
                        </li>
                        <li>
                          <span className="font-bold">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.informationCollected.bold4
                            }
                          </span>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.informationCollected.point4
                          }
                        </li>
                      </ul>
                      <h2 className="mt-10 text-xl font-bold">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title6
                        }
                      </h2>
                      <p className="mt-2 text-sm">
                        <span className="font-bold">
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.boldDesc
                          }
                        </span>
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.provideService.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.point1
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.point2
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.point3
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.point4
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.point5
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.point6
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.provideService.point7
                          }
                        </li>
                      </ul>
                      <br />
                      <p className="mt-2 text-sm">
                        <span className="font-bold">
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.boldDesc
                          }
                        </span>
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.maintainTrust.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point1
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point2
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point3
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point4
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point5
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point6
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point7
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point8
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point9
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point10
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.maintainTrust.point11
                          }
                        </li>
                      </ul>
                      <br />
                      <p className="mt-2 text-sm">
                        <span className="font-bold">
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.paymentService.boldDesc
                          }
                        </span>
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paymentService.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.paymentService.point1
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.paymentService.point2
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.paymentService.point3
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.paymentService.point4
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.paymentService.point5
                          }
                        </li>
                      </ul>
                      <h2 className="mt-10 text-xl font-bold">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title7
                        }
                      </h2>
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title8
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph4
                        }
                        <br />
                        <br />
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph5
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title9
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.facilitateCollection.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.facilitateCollection.point1
                          }
                        </li>
                      </ul>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.facilitateCollection.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.facilitateCollection.point1
                          }
                        </li>
                      </ul>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title10
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.complyLaw.paragraph1
                        }
                      </p>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.complyLaw.paragraph2
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title11
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph6
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title12
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.corporateAffiliates.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.corporateAffiliates.point1
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.corporateAffiliates.point1
                          }
                        </li>
                      </ul>
                      <h2 className="mt-10 text-xl font-bold">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title13
                        }
                      </h2>
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title14
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.communicateExp.paragraph1
                        }
                        <a href="/termscondition">
                          <span className="text-arusgreen-600 font-bold hover:text-arusgreen-500">
                            {
                              localeContent(language).privacypolicy
                                .termsandcondition.communicateExp.reference1
                            }
                          </span>
                        </a>
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.communicateExp.paragraph2
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title15
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.thirdPartyAcc.desc
                        }
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyAcc.point1
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyAcc.point2
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyAcc.point3
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyAcc.point4
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyAcc.point5
                          }
                        </li>
                        <li>
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyAcc.point6
                          }
                        </li>
                      </ul>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title16
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.thirdPartyPartner.paragraph1
                        }
                        <a
                          href={
                            "https://www.google.com/intl/en_us/help/terms_maps.html"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-arusgreen-600 font-bold hover:text-arusgreen-500"
                        >
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyPartner.reference1
                          }
                        </a>
                        ,
                        <a
                          href={"https://www.google.com/privacy.html"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-arusgreen-600 font-bold hover:text-arusgreen-500"
                        >
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyPartner.reference2
                          }
                        </a>
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.thirdPartyPartner.paragraph2
                        }
                        <a
                          href={
                            "https://policies.google.com/technologies/partner-sites"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-arusgreen-600 font-bold hover:text-arusgreen-500"
                        >
                          {
                            localeContent(language).privacypolicy
                              .termsandcondition.thirdPartyPartner.reference3
                          }
                        </a>
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.thirdPartyPartner.paragraph3
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-xl font-bold">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title17
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph19
                        }
                      </p>
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title18
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph20
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title19
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph21
                        }
                        <br />
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph22
                        }
                        <br />
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph23
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title20
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph24
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title21
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph25
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title22
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.paragraph26
                        }
                      </p>
                      <br />
                      <h3 className="mt-2 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.title23
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.contact.paragraph1
                        }
                      </p>
                      <h3 className="mt-10 text-md underline">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.contact.companyName
                        }
                      </h3>
                      <p className="mt-2 text-sm">
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.contact.address1
                        }
                        <br />
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.contact.address2
                        }
                        <br />
                        {
                          localeContent(language).privacypolicy
                            .termsandcondition.contact.address3
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}
